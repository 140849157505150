import SidePanel from '../../../shared/components/NewTable/SidePanel';
import { Supplier } from '../../../shared/types';
import SuppliersPlotTab from './SuppliersPlotsTab';

const SupplierSidePanel = ({ data = [] }: { data?: Supplier[] }) => {
  const details = [
    { label: 'Company name', key: 'name' },
    { label: 'Supplier name', key: 'supplierName' },
    { label: 'Contact', key: 'supplierName' },
  ];

  const tabs = [
    {
      label: 'Plots',
      render: (record?: Supplier) => <SuppliersPlotTab {...record} />,
    },
  ];
  return (
    <SidePanel
      titleKey="name"
      details={details as any}
      tabs={tabs}
      data={data}
      selectKey="supplier-id"
    />
  );
};

export default SupplierSidePanel;
