import { Box, Grid, Group, Tabs } from '@mantine/core';
import classes from './NewTable.module.css';
import { Text, Beta, Icon } from '@liveeo/component-library';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  SELECTED_PLOT_KEY,
  SELECTED_SUPPLIER_KEY,
  SelectedKey,
} from '../../../common/constants';
import ActionsMenu, { ActionItem } from './ActionMenu';

export type SidePanelDetail<T> = {
  label: string;
  key: keyof T;
  render?: (record: T) => React.ReactNode;
};

type SidePanelTab<T> = {
  label: string;
  render: (record?: T) => React.ReactNode;
};

export type SidePanelProps<T> = {
  titleKey?: keyof T;
  details?: SidePanelDetail<T>[];
  tabs?: SidePanelTab<T>[];
  selectKey?: SelectedKey;
  data: T[];
  addList?: (selectedData?: T) => ActionItem[];
};

const SidePanel = <T extends { id: string }>({
  titleKey,
  details,
  tabs,
  selectKey,
  data,
  addList,
}: SidePanelProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selected = searchParams.get(selectKey || '');
  const expanded = searchParams.get('expanded');
  const record = data?.find((record) => record.id === selected);
  const navigate = useNavigate();
  const { state } = useLocation();

  if (!record || expanded) {
    return null;
  }

  const handleClose = () => {
    [SELECTED_PLOT_KEY, SELECTED_SUPPLIER_KEY].forEach((key) => {
      searchParams.delete(key);
    });

    setSearchParams(searchParams);
  };

  const title = titleKey ? String(record?.[titleKey]) : '';

  const detailsList = details?.map((detail) => ({
    label: detail.label,
    value: (detail.render
      ? detail.render(record as T)
      : record?.[detail.key]) as React.ReactNode,
  }));

  const selectedData = data?.find((record) => record.id === selected);

  return (
    <Box className={classes.sidePanel}>
      <Group className={classes.sidePanelHeader}>
        <Box className={classes.sidePanelActions}>
          {state?.previousKey && (
            <Icon icon="chevron-left" onClick={() => navigate(-1)} size="lg" />
          )}
          <Text
            c="dark.0"
            fz={18}
            fw={700}
            w="200px"
            truncate="end"
            title={title}
          >
            {title}
          </Text>
        </Box>

        <Box className={classes.sidePanelActions}>
          {addList && (
            <ActionsMenu
              buttonStyle={{ padding: '0 4px' }}
              list={addList(selectedData)}
              offset={0}
            />
          )}
          <Beta.Icon icon="close" onClick={handleClose} size="xl" />
        </Box>
      </Group>

      <Grid justify="space-between">
        {detailsList?.map((detail) => (
          <Grid.Col span={6} key={detail.label}>
            <Box>
              <Text c="gray.6">{detail.label}</Text>
              <Text size="lg" truncate>
                {detail.value}
              </Text>
            </Box>
          </Grid.Col>
        ))}
      </Grid>

      <Tabs
        defaultValue={tabs?.[0]?.label}
        classNames={{
          list: classes.tabList,
          tabLabel: classes.tabLabel,
          tab: classes.tab,
        }}
      >
        <Tabs.List grow={true}>
          {tabs?.map((tab) => (
            <Tabs.Tab key={tab.label} value={tab.label}>
              <Text size="lg" fw="bold">
                {tab.label}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabs?.map((tab) => (
          <Tabs.Panel key={tab.label} value={tab.label}>
            {tab.render(record)}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Box>
  );
};

export default SidePanel;
